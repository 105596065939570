import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"
import Image from "gatsby-image"
import styled from "styled-components"
import { FormattedMessage } from "react-intl"

import Layout from "src/layout"
import { SIZES } from "src/layout/constants"
import { useLang } from "src/layout/root"
import { Section, Top, Label } from "src/components/styled"
import Laser from "src/svg/laser.svg"
import Tools from "src/svg/tools.svg"
import Scissors from "src/svg/scissors.svg"
import { scrollTo } from "src/helpers/scroll"

const Figures = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 10px ${SIZES.margin / 2}px;
  @media (max-width: 799px) {
    margin: 0 ${SIZES.mobileMargin / 2}px;
    a {
      flex: 0 1 50%;
    }
  }
`
const Figure = styled.figure`
  margin: ${SIZES.mobileMargin / 2}px;

  figcaption {
    letter-spacing: 2px;
    margin-top: 6px;
    font-size: 1.2em;
  }
  @media (min-width: 800px) {
    margin: ${SIZES.margin / 2}px;
    width: 250px;
  }
`
const CenteredCaption = styled.figcaption`
  text-align: center;
`
const CenteredLink = styled(Link)`
  display: flex;
  justify-content: center;
  margin: 0 ${SIZES.mobileMargin / 2}px;
`
const Service = styled.figure`
  text-align: center;
  margin: 15px ${SIZES.margin}px 0;
  font-weight: 500;
  svg {
    margin: 30px 0;
  }
  figcaption {
    text-transform: uppercase;
    letter-spacing: 2px;
    white-space: pre-line;
    font-size: 1em;
    line-height: 1.3em;
  }
  @media (max-width: 799px) {
    margin: 0 ${SIZES.mobileMargin / 2}px ${SIZES.mobileMargin}px;
    flex: 0 1 50%;
    figcaption {
      font-size: 0.8em;
    }
  }
`

interface DataType {
  cutting: { childImageSharp: Media }
  materials: { nodes: Material[] }
  patterns: { nodes: Pattern[] }
}

interface StateType {
  id: string
}

const ProductsPage: GatsbyPage<DataType, null, StateType> = ({ data, location }) => {
  const { getNodes } = useLang()
  const materials = getNodes<Material>(data.materials)
  const patterns = getNodes<Pattern>(data.patterns)

  useEffect(() => {
    if (location.state?.id) {
      scrollTo(location.state.id)
    }
  }, [location.state])

  return (
    <Layout title="products" subMenu="products">
      <Top id="material">
        <FormattedMessage id="products.materialTitle" tagName="h1" />
        <FormattedMessage id="products.materialSubtitle" tagName="h2" />
        <FormattedMessage id="products.materialLabel" tagName={Label} />
      </Top>
      <Figures>
        {materials.map(({ reference, photo, name }) => (
          <Link to={`/products/material/${reference}/`} key={reference}>
            <Figure>
              <Image fluid={photo.fluid} />
              <figcaption>{name}</figcaption>
            </Figure>
          </Link>
        ))}
      </Figures>
      <Top id="lining">
        <FormattedMessage id="products.liningTitle" tagName="h1" />
        <FormattedMessage id="products.liningSubtitle" tagName="h2" />
        <FormattedMessage id="products.liningLabel" tagName={Label} />
      </Top>
      <Figures>
        {patterns.map(({ reference, image, name }) => (
          <Link to={`/products/lining/${reference}/`} key={reference}>
            <Figure>
              <Image fluid={image.fluid} />
              <CenteredCaption>{name}</CenteredCaption>
            </Figure>
          </Link>
        ))}
      </Figures>
      <Top id="custom">
        <FormattedMessage id="products.customTitle" tagName="h1" />
        <FormattedMessage id="products.customSubtitle" tagName="h2" />
        <FormattedMessage id="products.customLabel" tagName={Label} />
      </Top>
      <CenteredLink to="/products/custom/">
        <Service>
          <Laser />
          <FormattedMessage id="products.serviceCutting" tagName="figcaption" />
        </Service>
        <Service>
          <Tools />
          <FormattedMessage id="products.serviceShapes" tagName="figcaption" />
        </Service>
        <Service>
          <Scissors />
          <FormattedMessage id="products.serviceConsulting" tagName="figcaption" />
        </Service>
      </CenteredLink>
      <Section>
        <Link to="/products/custom/">
          <Image fluid={data.cutting.childImageSharp.fluid} />
        </Link>
      </Section>
    </Layout>
  )
}

export default ProductsPage

export const query = graphql`
  query {
    cutting: file(relativePath: { eq: "products/cutting.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 75) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    materials: allContentfulMatiere(sort: { fields: position }) {
      nodes {
        node_locale
        reference
        name
        photo {
          fluid(maxWidth: 300, quality: 75) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
    patterns: allContentfulMotif(sort: { fields: position }) {
      nodes {
        node_locale
        reference
        name
        image {
          fluid(maxWidth: 300, quality: 75) {
            ...GatsbyContentfulFluid_noBase64
          }
        }
      }
    }
  }
`
